// This class is autogenerated. Do NOT modify
class endpointConstants {
    static global = { 
        adminConfiguration: {
            adminConfigurationSettings: "/admin/configuration/settings",
            adminConfigurationStudio: "/admin/configuration/studio",
            configurationStudio: "/configuration/studio",
            adminConfigurationClient: "/admin/configuration/client",
            configurationClient: "/configuration/client"
        },
        adminCpuCredits: {
            adminCpuCredits: "/admin/cpu-credits",
            adminCpuCreditsSync: "/admin/cpu-credits/sync",
            debugCpuCredits: "/debug/cpu-credits"
        },
        adminDatabases: {
            adminDatabases: "/admin/databases",
            adminDatabasesNode: "/admin/databases/node",
            adminDatabasesReorder: "/admin/databases/reorder",
            adminRestorePoints: "/admin/restore/points",
            adminRestoreDatabase: "/admin/restore/database",
            adminBackupTaskDelay: "/admin/backup-task/delay",
            adminDatabasesDisable: "/admin/databases/disable",
            adminDatabasesEnable: "/admin/databases/enable",
            adminDatabasesIndexing: "/admin/databases/indexing",
            adminDatabasesDynamicNodeDistribution: "/admin/databases/dynamic-node-distribution",
            adminDatabasesPromote: "/admin/databases/promote",
            adminConsole: "/admin/console",
            adminReplicationConflictsSolver: "/admin/replication/conflicts/solver",
            adminCompact: "/admin/compact",
            adminDatabasesUnusedIds: "/admin/databases/unused-ids",
            adminMigrate: "/admin/migrate",
            adminMigrateOffline: "/admin/migrate/offline",
            adminDatabasesSetLock: "/admin/databases/set-lock"
        },
        adminDebugQueryClauseCache: {
            adminIndexesLuceneQueryClauseCache: "/admin/indexes/lucene/query-clause-cache"
        },
        adminDump: {
            adminDebugDump: "/admin/debug/dump",
            adminDebugGcdump: "/admin/debug/gcdump"
        },
        adminGcDebug: {
            adminDebugMemoryAllocations: "/admin/debug/memory/allocations",
            adminDebugMemoryGcEvents: "/admin/debug/memory/gc-events"
        },
        adminIoMetrics: {
            adminDebugIoMetrics: "/admin/debug/io-metrics",
            adminDebugIoMetricsLive: "/admin/debug/io-metrics/live"
        },
        adminMetrics: {
            adminMetrics: "/admin/metrics"
        },
        adminMonitoring: {
            adminMonitoringV1Server: "/admin/monitoring/v1/server",
            adminMonitoringV1Databases: "/admin/monitoring/v1/databases",
            adminMonitoringV1Indexes: "/admin/monitoring/v1/indexes",
            adminMonitoringV1Collections: "/admin/monitoring/v1/collections"
        },
        adminPrometheusMonitoring: {
            adminMonitoringV1Prometheus: "/admin/monitoring/v1/prometheus"
        },
        adminServerWide: {
            adminConfigurationServerWide: "/admin/configuration/server-wide",
            adminConfigurationServerWideBackup: "/admin/configuration/server-wide/backup",
            adminConfigurationServerWideExternalReplication: "/admin/configuration/server-wide/external-replication",
            adminConfigurationServerWideTask: "/admin/configuration/server-wide/task",
            adminConfigurationServerWideTasks: "/admin/configuration/server-wide/tasks",
            adminConfigurationServerWideState: "/admin/configuration/server-wide/state"
        },
        adminStats: {
            adminStats: "/admin/stats"
        },
        adminStorage: {
            adminDebugStorageEnvironmentReport: "/admin/debug/storage/environment/report",
            adminDebugStorageEnvironmentScratchBufferInfo: "/admin/debug/storage/environment/scratch-buffer-info"
        },
        adminStudioServerWide: {
            adminServerWideTasks: "/admin/server-wide/tasks",
            adminServerWideBackupDataDirectory: "/admin/server-wide/backup-data-directory"
        },
        adminTcpConnectionDebugInfo: {
            adminDebugInfoTcpStats: "/admin/debug/info/tcp/stats",
            adminDebugInfoTcpActiveConnections: "/admin/debug/info/tcp/active-connections"
        },
        backupDatabase: {
            periodicBackup: "/periodic-backup",
            periodicBackupStatus: "/periodic-backup/status",
            adminDebugPeriodicBackupTimers: "/admin/debug/periodic-backup/timers"
        },
        buildVersion: {
            buildVersion: "/build/version",
            buildVersionUpdates: "/build/version/updates"
        },
        databasesDebug: {
            adminDebugDatabasesIdle: "/admin/debug/databases/idle"
        },
        databases: {
            databases: "/databases",
            adminDatabasesTopologyModify: "/admin/databases/topology/modify",
            topology: "/topology",
            debugIsLoaded: "/debug/is-loaded",
            adminRemoteServerBuildVersion: "/admin/remote-server/build/version"
        },
        debug: {
            debugRoutes: "/debug/routes"
        },
        emptyMessage: {
            adminTestEmptyMessage: "/admin/test/empty-message",
            adminTestDelay: "/admin/test/delay",
            adminTestSizedMessage: "/admin/test/sized-message"
        },
        setup: {
            setupAlive: "/setup/alive",
            setupDnsNCert: "/setup/dns-n-cert",
            setupUserDomains: "/setup/user-domains",
            setupPopulateIps: "/setup/populate-ips",
            setupParameters: "/setup/parameters",
            setupIps: "/setup/ips",
            setupHosts: "/setup/hosts",
            setupUnsecuredPackage: "/setup/unsecured/package",
            setupSecured: "/setup/secured",
            setupLetsencryptAgreement: "/setup/letsencrypt/agreement",
            setupLetsencrypt: "/setup/letsencrypt",
            setupContinueExtract: "/setup/continue/extract",
            setupContinueUnsecured: "/setup/continue/unsecured",
            setupContinue: "/setup/continue",
            setupFinish: "/setup/finish"
        },
        shardedAdminDatabase: {
            adminDatabasesOrchestrator: "/admin/databases/orchestrator",
            adminDatabasesShard: "/admin/databases/shard"
        },
        studioDatabases: {
            studioTasksDatabases: "/studio-tasks/databases",
            studioTasksDatabasesState: "/studio-tasks/databases/state"
        },
        studio: {
            favicon_ico: "/favicon.ico",
            authError_html: "/auth-error.html",
            eulaIndex_html: "/eula/index.html",
            eula$: "/eula/",
            _2faIndex_html: "/2fa/index.html",
            _2fa$: "/2fa/",
            wizardIndex_html: "/wizard/index.html",
            wizard$: "/wizard/",
            studioIndex_html: "/studio/index.html",
            studio$: "/studio/"
        },
        tcpConnectionInfo: {
            infoTcp: "/info/tcp",
            infoRemoteTaskTopology: "/info/remote-task/topology",
            infoRemoteTaskTcp: "/info/remote-task/tcp"
        },
        testConnection: {
            adminTestConnection: "/admin/test-connection"
        },
        adminSorters: {
            adminSorters: "/admin/sorters"
        },
        sorters: {
            sorters: "/sorters"
        },
        adminAnalyzers: {
            adminAnalyzers: "/admin/analyzers"
        },
        analyzers: {
            analyzers: "/analyzers"
        },
        license: {
            licenseEula: "/license/eula",
            adminLicenseEulaAccept: "/admin/license/eula/accept",
            licenseStatus: "/license/status",
            licenseConfiguration: "/license/configuration",
            adminLicenseActivate: "/admin/license/activate",
            adminLicenseForceUpdate: "/admin/license/forceUpdate",
            licenseSupport: "/license/support",
            adminLicenseRenew: "/admin/license/renew",
            licenseServerConnectivity: "/license-server/connectivity",
            licenseLimitsUsage: "/license/limits-usage"
        },
        studioFeedback: {
            studioFeedback: "/studio/feedback"
        },
        studioTasks: {
            adminStudioTasksFullDataDirectory: "/admin/studio-tasks/full-data-directory",
            adminStudioTasksFolderPathOptions: "/admin/studio-tasks/folder-path-options",
            adminStudioTasksOfflineMigrationTest: "/admin/studio-tasks/offline-migration-test",
            studioTasksPeriodicBackupTestCredentials: "/studio-tasks/periodic-backup/test-credentials",
            studioTasksIsValidName: "/studio-tasks/is-valid-name",
            studioTasksAdminMigratorPath: "/studio-tasks/admin/migrator-path",
            studioTasksBootstrap: "/studio-tasks/bootstrap",
            studioTasksFormat: "/studio-tasks/format",
            studioTasksNextCronExpressionOccurrence: "/studio-tasks/next-cron-expression-occurrence"
        },
        upgradeInfo: {
            studioUpgradeInfo: "/studio/upgrade-info"
        },
        operationsServer: {
            adminOperationsNextOperationId: "/admin/operations/next-operation-id",
            adminOperationsKill: "/admin/operations/kill",
            operationsState: "/operations/state"
        },
        adminCertificates: {
            adminCertificates2faGenerate: "/admin/certificates/2fa/generate",
            adminCertificates: "/admin/certificates",
            adminCertificatesPurge: "/admin/certificates/purge",
            certificatesWhoami: "/certificates/whoami",
            adminCertificatesEdit: "/admin/certificates/edit",
            adminCertificatesExport: "/admin/certificates/export",
            adminCertificatesMode: "/admin/certificates/mode",
            adminCertificatesClusterDomains: "/admin/certificates/cluster-domains",
            adminCertificatesReplacementReset: "/admin/certificates/replacement/reset",
            adminCertificatesReplacementStatus: "/admin/certificates/replacement/status",
            adminCertificatesLetsencryptRenewalDate: "/admin/certificates/letsencrypt/renewal-date",
            adminCertificatesLetsencryptForceRenew: "/admin/certificates/letsencrypt/force-renew",
            adminCertificatesRefresh: "/admin/certificates/refresh",
            adminCertificatesReplaceClusterCert: "/admin/certificates/replace-cluster-cert",
            adminCertificatesLocalState: "/admin/certificates/local-state",
            adminCertificatesLocalStateApply: "/admin/certificates/local-state/apply"
        },
        twoFactorAuthentication: {
            authentication2faConfiguration: "/authentication/2fa/configuration",
            authentication2fa: "/authentication/2fa"
        },
        trafficWatch: {
            adminTrafficWatch: "/admin/traffic-watch",
            adminTrafficWatchConfiguration: "/admin/traffic-watch/configuration"
        },
        clusterDashboard: {
            clusterDashboardWatch: "/cluster-dashboard/watch",
            adminClusterDashboardRemoteWatch: "/admin/cluster-dashboard/remote/watch"
        },
        serverNotificationCenter: {
            serverNotificationCenterWatch: "/server/notification-center/watch",
            serverNotificationCenterDismiss: "/server/notification-center/dismiss",
            serverNotificationCenterPostpone: "/server/notification-center/postpone"
        },
        threadsInfo: {
            threadsInfoWatch: "/threads-info/watch"
        },
        snmp: {
            monitoringSnmp: "/monitoring/snmp",
            monitoringSnmpBulk: "/monitoring/snmp/bulk",
            monitoringSnmpOids: "/monitoring/snmp/oids"
        },
        resharding: {
            adminReshardingStart: "/admin/resharding/start",
            debugShardingFindBucket: "/debug/sharding/find/bucket"
        },
        secretKey: {
            adminSecrets: "/admin/secrets",
            adminSecretsGenerate: "/admin/secrets/generate",
            secretsGenerate: "/secrets/generate",
            adminSecretsDistribute: "/admin/secrets/distribute"
        },
        memoryDebug: {
            adminDebugMemoryGc: "/admin/debug/memory/gc",
            adminDebugMemoryLowMemLog: "/admin/debug/memory/low-mem-log",
            adminDebugProcStatus: "/admin/debug/proc/status",
            adminDebugProcMeminfo: "/admin/debug/proc/meminfo",
            adminDebugMemorySmaps: "/admin/debug/memory/smaps",
            adminDebugMemoryStats: "/admin/debug/memory/stats",
            adminDebugMemoryEncryptionBufferPool: "/admin/debug/memory/encryption-buffer-pool"
        },
        nodeDebug: {
            adminDebugNodeClearHttpClientsPool: "/admin/debug/node/clear-http-clients-pool",
            adminDebugNodeRemoteConnections: "/admin/debug/node/remote-connections",
            adminDebugNodeEngineLogs: "/admin/debug/node/engine-logs",
            adminDebugNodeStateChangeHistory: "/admin/debug/node/state-change-history",
            adminDebugNodePing: "/admin/debug/node/ping"
        },
        procStats: {
            adminDebugCpuStats: "/admin/debug/cpu/stats",
            adminDebugProcStats: "/admin/debug/proc/stats"
        },
        serverInfo: {
            debugServerId: "/debug/server-id"
        },
        serverTransactionDebug: {
            adminDebugTxinfo: "/admin/debug/txinfo"
        },
        serverWideDebugInfoPackage: {
            adminDebugRemoteClusterInfoPackage: "/admin/debug/remote-cluster-info-package",
            adminDebugClusterInfoPackage: "/admin/debug/cluster-info-package",
            adminDebugInfoPackage: "/admin/debug/info-package"
        },
        serverWideQueriesDebug: {
            debugQueriesRunningLive: "/debug/queries/running/live"
        },
        threads: {
            adminDebugThreadsStackTrace: "/admin/debug/threads/stack-trace",
            adminDebugThreadsRunaway: "/admin/debug/threads/runaway"
        },
        adminLogs: {
            adminLogsConfiguration: "/admin/logs/configuration",
            adminLogsWatch: "/admin/logs/watch",
            adminLogsDownload: "/admin/logs/download",
            adminLogsMicrosoftLoggers: "/admin/logs/microsoft/loggers",
            adminLogsMicrosoftConfiguration: "/admin/logs/microsoft/configuration",
            adminLogsMicrosoftState: "/admin/logs/microsoft/state",
            adminLogsMicrosoftEnable: "/admin/logs/microsoft/enable",
            adminLogsMicrosoftDisable: "/admin/logs/microsoft/disable"
        },
        adminMemory: {
            adminMemoryGc: "/admin/memory/gc"
        },
        adminScriptRunnersDebugInfo: {
            adminDebugScriptRunners: "/admin/debug/script-runners"
        },
        rachisAdmin: {
            adminRachisSend: "/admin/rachis/send",
            rachisWaitfor: "/rachis/waitfor",
            adminClusterObserverSuspend: "/admin/cluster/observer/suspend",
            adminClusterObserverDecisions: "/admin/cluster/observer/decisions",
            adminClusterLog: "/admin/cluster/log",
            adminDebugClusterHistoryLogs: "/admin/debug/cluster/history-logs",
            clusterNodeInfo: "/cluster/node-info",
            clusterTopology: "/cluster/topology",
            adminClusterMaintenanceStats: "/admin/cluster/maintenance-stats",
            adminClusterBootstrap: "/admin/cluster/bootstrap",
            adminClusterNode: "/admin/cluster/node",
            adminLicenseSetLimit: "/admin/license/set-limit",
            adminClusterTimeout: "/admin/cluster/timeout",
            adminClusterReelect: "/admin/cluster/reelect",
            adminClusterPromote: "/admin/cluster/promote",
            adminClusterDemote: "/admin/cluster/demote",
            adminClusterRemoveEntryFromLog: "/admin/cluster/remove-entry-from-log"
        }
    }
    static databases = { 
        compareExchange: {
            cmpxchg: "/cmpxchg"
        },
        databaseTcpConnectionInfo: {
            infoTcp: "/info/tcp"
        },
        ongoingTasks: {
            tasks: "/tasks",
            adminPeriodicBackupConfig: "/admin/periodic-backup/config",
            adminDebugPeriodicBackupTimers: "/admin/debug/periodic-backup/timers",
            adminPeriodicBackup: "/admin/periodic-backup",
            adminBackupDataDirectory: "/admin/backup-data-directory",
            adminBackupDatabase: "/admin/backup/database",
            adminBackup: "/admin/backup",
            adminConnectionStrings: "/admin/connection-strings",
            adminEtl: "/admin/etl",
            task: "/task",
            tasksPullReplicationHub: "/tasks/pull-replication/hub",
            subscriptionTasksState: "/subscription-tasks/state",
            adminTasksState: "/admin/tasks/state",
            adminTasksExternalReplication: "/admin/tasks/external-replication",
            subscriptionTasks: "/subscription-tasks",
            adminTasks: "/admin/tasks",
            adminQueueSink: "/admin/queue-sink"
        },
        sampleData: {
            studioSampleData: "/studio/sample-data",
            studioSampleDataClasses: "/studio/sample-data/classes"
        },
        sqlMigration: {
            adminSqlMigrationSchema: "/admin/sql-migration/schema",
            adminSqlMigrationImport: "/admin/sql-migration/import",
            adminSqlMigrationTest: "/admin/sql-migration/test"
        },
        studioCollectionFields: {
            studioCollectionsFields: "/studio/collections/fields"
        },
        studioCollections: {
            studioCollectionsPreview: "/studio/collections/preview",
            studioCollectionsDocs: "/studio/collections/docs"
        },
        studioDatabaseTasks: {
            adminStudioTasksFolderPathOptions: "/admin/studio-tasks/folder-path-options",
            studioTasksIndexesConfigurationDefaults: "/studio-tasks/indexes/configuration/defaults",
            adminStudioTasksRestart: "/admin/studio-tasks/restart",
            studioTasksSuggestConflictResolution: "/studio-tasks/suggest-conflict-resolution"
        },
        studioIndex: {
            studioIndexesErrorsCount: "/studio/indexes/errors-count",
            studioIndexType: "/studio/index-type",
            studioIndexFields: "/studio/index-fields"
        },
        studioStats: {
            studioFooterStats: "/studio/footer/stats",
            studioLicenseLimitsUsage: "/studio/license/limits-usage"
        },
        buckets: {
            debugShardingBuckets: "/debug/sharding/buckets",
            debugShardingBucket: "/debug/sharding/bucket"
        },
        operations: {
            operationsNextOperationId: "/operations/next-operation-id",
            operationsKill: "/operations/kill",
            operations: "/operations",
            operationsState: "/operations/state"
        },
        smuggler: {
            smugglerValidateOptions: "/smuggler/validate-options",
            smugglerExport: "/smuggler/export",
            adminSmugglerImport: "/admin/smuggler/import",
            adminSmugglerImportS3Dir: "/admin/smuggler/import-s3-dir",
            adminSmugglerImportDir: "/admin/smuggler/import-dir",
            adminSmugglerMigrateRavendb: "/admin/smuggler/migrate/ravendb",
            migrateGetMigratedServerUrls: "/migrate/get-migrated-server-urls",
            adminSmugglerMigrate: "/admin/smuggler/migrate",
            smugglerImport: "/smuggler/import",
            smugglerImportCsv: "/smuggler/import/csv"
        },
        databaseNotificationCenter: {
            notifications: "/notifications",
            notificationCenterWatch: "/notification-center/watch",
            notificationCenterDismiss: "/notification-center/dismiss",
            notificationCenterPostpone: "/notification-center/postpone",
            notificationCenterStats: "/notification-center/stats"
        },
        postgreSqlIntegration: {
            adminIntegrationsPostgresqlServerStatus: "/admin/integrations/postgresql/server/status",
            adminIntegrationsPostgresqlUsers: "/admin/integrations/postgresql/users",
            adminIntegrationsPostgresqlUser: "/admin/integrations/postgresql/user",
            adminIntegrationsPostgresqlConfig: "/admin/integrations/postgresql/config"
        },
        queueSink: {
            adminQueueSinkTest: "/admin/queue-sink/test",
            queueSinkPerformanceLive: "/queue-sink/performance/live"
        },
        analyzers: {
            analyzers: "/analyzers"
        },
        attachment: {
            attachments: "/attachments",
            attachmentsBulk: "/attachments/bulk",
            debugAttachmentsHash: "/debug/attachments/hash",
            debugAttachmentsMetadata: "/debug/attachments/metadata"
        },
        changes: {
            changes: "/changes",
            changesDebug: "/changes/debug"
        },
        collections: {
            collectionsStats: "/collections/stats",
            collectionsStatsDetailed: "/collections/stats/detailed",
            collectionsDocs: "/collections/docs",
            collectionsLastChangeVector: "/collections/last-change-vector"
        },
        configuration: {
            configurationStudio: "/configuration/studio",
            configurationClient: "/configuration/client"
        },
        counters: {
            counters: "/counters"
        },
        dataArchival: {
            dataArchivalConfig: "/data-archival/config",
            adminDataArchivalConfig: "/admin/data-archival/config"
        },
        document: {
            docs: "/docs",
            docsSize: "/docs/size",
            docsClass: "/docs/class"
        },
        documentsCompression: {
            documentsCompressionConfig: "/documents-compression/config",
            adminDocumentsCompressionConfig: "/admin/documents-compression/config"
        },
        expiration: {
            expirationConfig: "/expiration/config",
            adminExpirationConfig: "/admin/expiration/config"
        },
        hiLo: {
            hiloNext: "/hilo/next",
            hiloReturn: "/hilo/return"
        },
        identity: {
            identityNext: "/identity/next",
            identitySeed: "/identity/seed"
        },
        index: {
            indexesReplace: "/indexes/replace",
            indexesFinishRolling: "/indexes/finish-rolling",
            indexesSource: "/indexes/source",
            indexesHistory: "/indexes/history",
            indexesHasChanged: "/indexes/has-changed",
            indexesDebug: "/indexes/debug",
            indexes: "/indexes",
            indexesStats: "/indexes/stats",
            indexesStaleness: "/indexes/staleness",
            indexesProgress: "/indexes/progress",
            indexOpenFaultyIndex: "/index/open-faulty-index",
            indexesCSharpIndexDefinition: "/indexes/c-sharp-index-definition",
            indexesStatus: "/indexes/status",
            indexesSetLock: "/indexes/set-lock",
            indexesSetPriority: "/indexes/set-priority",
            indexesErrors: "/indexes/errors",
            indexesTerms: "/indexes/terms",
            indexesTotalTime: "/indexes/total-time",
            indexesPerformance: "/indexes/performance",
            indexesPerformanceLive: "/indexes/performance/live",
            indexesSuggestIndexMerge: "/indexes/suggest-index-merge",
            indexesTry: "/indexes/try",
            indexesDebugMetadata: "/indexes/debug/metadata"
        },
        ioMetrics: {
            debugIoMetrics: "/debug/io-metrics",
            debugIoMetricsLive: "/debug/io-metrics/live"
        },
        jsonPatch: {
            jsonPatch: "/json-patch"
        },
        legacyReplication: {
            replicationLastEtag: "/replication/lastEtag",
            replicationReplicateDocs: "/replication/replicateDocs",
            replicationReplicateAttachments: "/replication/replicateAttachments",
            replicationHeartbeat: "/replication/heartbeat",
            indexesLastQueried: "/indexes/last-queried",
            sideBySideIndexes: "/side-by-side-indexes",
            transformers$: "/transformers/"
        },
        multiGet: {
            multi_get: "/multi_get"
        },
        performanceMetrics: {
            debugPerfMetrics: "/debug/perf-metrics"
        },
        pullReplication: {
            adminTasksPullReplicationHub: "/admin/tasks/pull-replication/hub",
            adminTasksPullReplicationHubAccess: "/admin/tasks/pull-replication/hub/access",
            adminTasksSinkPullReplication: "/admin/tasks/sink-pull-replication",
            adminPullReplicationGenerateCertificate: "/admin/pull-replication/generate-certificate"
        },
        queries: {
            queries: "/queries",
            queriesTest: "/queries/test"
        },
        rachisDatabase: {
            adminRachisWaitForIndexNotifications: "/admin/rachis/wait-for-index-notifications"
        },
        refresh: {
            refreshConfig: "/refresh/config",
            adminRefreshConfig: "/admin/refresh/config"
        },
        replication: {
            replicationTombstones: "/replication/tombstones",
            replicationConflicts: "/replication/conflicts",
            replicationPerformance: "/replication/performance",
            replicationPerformanceLive: "/replication/performance/live",
            replicationPulsesLive: "/replication/pulses/live",
            replicationActiveConnections: "/replication/active-connections",
            replicationDebugOutgoingFailures: "/replication/debug/outgoing-failures",
            replicationDebugIncomingLastActivityTime: "/replication/debug/incoming-last-activity-time",
            replicationDebugIncomingRejectionInfo: "/replication/debug/incoming-rejection-info",
            replicationDebugOutgoingReconnectQueue: "/replication/debug/outgoing-reconnect-queue",
            replicationConflictsSolver: "/replication/conflicts/solver",
            debugReplicationAllItems: "/debug/replication/all-items"
        },
        revisions: {
            revisionsConfig: "/revisions/config",
            revisionsConflictsConfig: "/revisions/conflicts/config",
            revisionsCount: "/revisions/count",
            revisions: "/revisions",
            revisionsRevert: "/revisions/revert",
            revisionsResolved: "/revisions/resolved",
            revisionsBin: "/revisions/bin",
            debugDocumentsGetRevisions: "/debug/documents/get-revisions"
        },
        sorters: {
            sorters: "/sorters"
        },
        stats: {
            statsEssential: "/stats/essential",
            statsDetailed: "/stats/detailed",
            stats: "/stats",
            healthcheck: "/healthcheck",
            metrics: "/metrics",
            metricsPuts: "/metrics/puts",
            metricsBytes: "/metrics/bytes",
            adminValidateUnusedIds: "/admin/validate-unused-ids"
        },
        subscriptions: {
            subscriptionsTry: "/subscriptions/try",
            subscriptions: "/subscriptions",
            subscriptionsState: "/subscriptions/state",
            debugSubscriptionsResend: "/debug/subscriptions/resend",
            subscriptionsConnectionDetails: "/subscriptions/connection-details",
            subscriptionsPerformanceLive: "/subscriptions/performance/live",
            subscriptionsDrop: "/subscriptions/drop",
            subscriptionsUpdate: "/subscriptions/update"
        },
        tcpManagement: {
            tcp: "/tcp"
        },
        timeSeries: {
            timeseriesStats: "/timeseries/stats",
            timeseriesRanges: "/timeseries/ranges",
            timeseries: "/timeseries",
            timeseriesConfig: "/timeseries/config",
            adminTimeseriesConfig: "/admin/timeseries/config",
            timeseriesNamesConfig: "/timeseries/names/config",
            timeseriesDebugSegmentsSummary: "/timeseries/debug/segments-summary"
        },
        transactionsRecording: {
            transactionsReplay: "/transactions/replay",
            adminTransactionsStartRecording: "/admin/transactions/start-recording",
            adminTransactionsStopRecording: "/admin/transactions/stop-recording"
        },
        streaming: {
            streamsDocs: "/streams/docs",
            streamsTimeseries: "/streams/timeseries",
            streamsQueries: "/streams/queries"
        },
        databaseDebugInfoPackage: {
            debugInfoPackage: "/debug/info-package"
        },
        documentDebug: {
            debugDocumentsHuge: "/debug/documents/huge"
        },
        allDocumentIdsDebug: {
            debugDocumentsExportAllIds: "/debug/documents/export-all-ids"
        },
        identityDebug: {
            debugIdentities: "/debug/identities"
        },
        queriesDebug: {
            debugQueriesKill: "/debug/queries/kill",
            debugQueriesRunning: "/debug/queries/running",
            debugQueriesCacheList: "/debug/queries/cache/list"
        },
        scriptRunnersDebugInfo: {
            debugScriptRunners: "/debug/script-runners"
        },
        storage: {
            adminStorageManualFlush: "/admin/storage/manual-flush",
            adminStorageManualSync: "/admin/storage/manual-sync",
            debugStorageEnvironmentDebugOnlyPages: "/debug/storage/environment/debug-only/pages",
            debugStorageTrees: "/debug/storage/trees",
            debugStorageBtreeStructure: "/debug/storage/btree-structure",
            debugStorageFstStructure: "/debug/storage/fst-structure",
            debugStorageReport: "/debug/storage/report",
            debugStorageAllEnvironmentsReport: "/debug/storage/all-environments/report",
            debugStorageEnvironmentReport: "/debug/storage/environment/report",
            debugStorageCompressionDictionaries: "/debug/storage/compression-dictionaries",
            debugStorageEnvironmentScratchBufferInfo: "/debug/storage/environment/scratch-buffer-info"
        },
        transactionDebug: {
            adminDebugTxinfo: "/admin/debug/txinfo",
            adminDebugClusterTxinfo: "/admin/debug/cluster/txinfo"
        },
        bulkInsert: {
            bulk_insert: "/bulk_insert"
        },
        batch: {
            bulk_docs: "/bulk_docs"
        },
        adminAnalyzers: {
            adminAnalyzers: "/admin/analyzers"
        },
        adminConfiguration: {
            adminConfigurationSettings: "/admin/configuration/settings",
            adminRecord: "/admin/record",
            adminConfigurationStudio: "/admin/configuration/studio",
            adminConfigurationClient: "/admin/configuration/client"
        },
        adminIndex: {
            adminIndexes: "/admin/indexes",
            indexes: "/indexes",
            indexesTest: "/indexes/test",
            adminIndexesStop: "/admin/indexes/stop",
            adminIndexesStart: "/admin/indexes/start",
            adminIndexesEnable: "/admin/indexes/enable",
            adminIndexesDisable: "/admin/indexes/disable",
            adminIndexesDump: "/admin/indexes/dump",
            adminIndexesOptimize: "/admin/indexes/optimize"
        },
        adminRevisions: {
            adminRevisions: "/admin/revisions",
            adminRevisionsConflictsConfig: "/admin/revisions/conflicts/config",
            adminRevisionsConfig: "/admin/revisions/config",
            adminRevisionsConfigEnforce: "/admin/revisions/config/enforce",
            adminRevisionsOrphanedAdopt: "/admin/revisions/orphaned/adopt"
        },
        adminSharding: {
            adminShardingReshardingCleanup: "/admin/sharding/resharding/cleanup"
        },
        adminSorters: {
            adminSorters: "/admin/sorters"
        },
        adminTimeSeries: {
            adminTimeseriesPolicy: "/admin/timeseries/policy"
        },
        adminTombstone: {
            adminTombstonesCleanup: "/admin/tombstones/cleanup",
            adminTombstonesState: "/admin/tombstones/state"
        },
        sqlEtl: {
            adminEtlSqlTestConnection: "/admin/etl/sql/test-connection",
            adminEtlSqlTest: "/admin/etl/sql/test"
        },
        ravenEtl: {
            adminEtlRavenTest: "/admin/etl/raven/test"
        },
        queueEtlConnection: {
            adminEtlQueueKafkaTestConnection: "/admin/etl/queue/kafka/test-connection",
            adminEtlQueueRabbitmqTestConnection: "/admin/etl/queue/rabbitmq/test-connection"
        },
        queueEtl: {
            adminEtlQueueTest: "/admin/etl/queue/test"
        },
        olapEtl: {
            adminEtlOlapTest: "/admin/etl/olap/test"
        },
        elasticSearchEtlConnection: {
            adminEtlElasticsearchTestConnection: "/admin/etl/elasticsearch/test-connection"
        },
        elasticSearchEtl: {
            adminEtlElasticsearchTest: "/admin/etl/elasticsearch/test"
        },
        etl: {
            etlStats: "/etl/stats",
            etlDebugStats: "/etl/debug/stats",
            etlPerformance: "/etl/performance",
            etlPerformanceLive: "/etl/performance/live",
            etlProgress: "/etl/progress"
        }
    }
}

export = endpointConstants;
